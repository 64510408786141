import { ProviderAccount } from '@pochico/shared';
import React from 'react';

import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { useSetDocumentTitle } from '../../commons/components/setDocumentTitle';
import { BookingFormEdit } from '../../components/features/bookingForms/edit';

const BookingFormEditPage: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  useSetDocumentTitle('質問フォームの編集');

  const location = useLocation();
  const id = React.useMemo(() => {
    const path = location.pathname.split('/');
    return path[path.length - 2]; // 末尾はshow
  }, [location.pathname]);
  const bookingForm = React.useMemo(() => {
    // createした直後にeditに来るときはstateに作成済みformが入っている
    const state = location.state as { bookingForm: string } | undefined;
    if (!state) {
      return undefined;
    }
    const parsed = JSON.parse(state.bookingForm);
    // JSONで単純にparseできないフィールドはここで変換
    return {
      ...parsed,
      createTime: dayjs(parsed.createTime).toDate(),
      updateTime: dayjs(parsed.updateTime).toDate(),
    };
  }, [location.state]);
  return (
    <BookingFormEdit
      providerAccount={providerAccount}
      id={id}
      bookingForm={bookingForm}
    />
  );
};

export default BookingFormEditPage;
