import {
  Divider,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ProviderAccount, SharedBookingFormElement } from '@pochico/shared';
import React from 'react';

import dayjs from 'dayjs';
import { useIsPC } from '../../../hooks/useIsPC';
import { LineUserFilterUI } from '../LineUsers';
import { ResourceFilter } from '../resourceFilter/ResourceFilter';

const lineUserStatusChoices = [
  { id: '', name: 'フィルタなし' },
  { id: 'active', name: '有効' },
  { id: 'blocked', name: 'ブロック' },
];

const prefix = 'sharedFormInputs.';
const LINE_USER_FILTER_FORM_ELEMENT_IDS = [
  'name',
  'email',
  'tel',
  'memberId',
] as SharedBookingFormElement['id'][];

export const LineUserFilterInputs: React.FC<{
  providerAccount: ProviderAccount;
  filter: LineUserFilterUI;
  enabledSharedBookingFormElements: SharedBookingFormElement[];
  onFilterChange: (filter: LineUserFilterUI) => void;
}> = ({
  providerAccount,
  filter,
  enabledSharedBookingFormElements,
  onFilterChange,
}) => {
  const filterToString = React.useMemo(
    () => (filter: LineUserFilterUI) => {
      const strs = [];
      if (filter.sharedFormInputs) {
        const formInputStrs = Object.entries(filter.sharedFormInputs).map(
          ([type, value]) => {
            const element = enabledSharedBookingFormElements.find(
              (element) => element.type === type
            );
            const _value = value || '';
            return element
              ? `${element.name}: ${_value}`
              : `${type}: ${_value}`;
          }
        );
        if (formInputStrs.length > 0) {
          strs.push(formInputStrs.join(' / '));
          // 最も強いフィルタが有効なのでそれだけ表示する
          return strs.join(' / ');
        }
      }
      if (filter.displayName) {
        strs.push(`${filter.displayName}`);
      }
      if (filter.displayNameByProvider) {
        strs.push(filter.displayNameByProvider);
      }
      if (filter.status) {
        strs.push(filter.status === 'active' ? '有効' : 'ブロック');
      }
      if (filter.lastBookedAt) {
        const { start, end } = filter.lastBookedAt;
        if (start && end) {
          strs.push(`${start} ~ ${end}`);
        } else if (start) {
          strs.push(`${start} ~`);
        } else if (end) {
          strs.push(`~ ${end}`);
        }
      }
      return strs.join(' / ');
    },
    [enabledSharedBookingFormElements]
  );
  return (
    <ResourceFilter
      defaultValue={filter}
      applyFilter={onFilterChange}
      filterToString={filterToString}
    >
      {({ filter, setFilter }) => (
        <LineUserFilterInputInner
          providerAccount={providerAccount}
          filter={filter}
          onFilterChange={setFilter}
          enabledSharedBookingFormElements={enabledSharedBookingFormElements}
        />
      )}
    </ResourceFilter>
  );
};
const LineUserFilterInputInner: React.FC<{
  providerAccount: ProviderAccount;
  filter: LineUserFilterUI;
  enabledSharedBookingFormElements: SharedBookingFormElement[];
  onFilterChange: (filter: LineUserFilterUI) => void;
}> = ({
  providerAccount,
  filter,
  enabledSharedBookingFormElements,
  onFilterChange,
}) => {
  const isPC = useIsPC();
  const today = React.useMemo(() => dayjs(), []);
  const [filterKey, setFilterKey] = React.useState<keyof LineUserFilterUI>(
    () => {
      if (filter.sharedFormInputs) {
        const query = Object.entries(filter.sharedFormInputs).find(
          ([, _value]) => Boolean(_value)
        );
        if (query) {
          return `${prefix}${query[0]}` as keyof LineUserFilterUI;
        }
      }
      return 'displayName';
    }
  );
  const { selectDefaultValue, inputDefaultValue } = React.useMemo(() => {
    if (filterKey.startsWith(prefix)) {
      return {
        selectDefaultValue: filterKey,
        inputDefaultValue: filter['sharedFormInputs']?.[
          filterKey.replace(
            prefix,
            ''
          ) as keyof LineUserFilterUI['sharedFormInputs']
        ] as unknown as string,
      };
    } else {
      return {
        selectDefaultValue: filterKey,
        inputDefaultValue: filter[filterKey] as string,
      };
    }
  }, [filter, filterKey]);

  // LINEユーザー検索の検索対象のフィールドを変更したとき
  const onSelectChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.currentTarget.value as keyof LineUserFilterUI;
      const oldKey = filterKey;

      if (value.startsWith(prefix)) {
        // 個人情報質問で収集した情報での検索
        const newKey = value.replace(prefix, '');
        if (oldKey.startsWith(prefix)) {
          const _oldKey = oldKey.replace(
            prefix,
            ''
          ) as SharedBookingFormElement['id'];
          onFilterChange({
            ...filter,
            sharedFormInputs: {
              [newKey]: filter.sharedFormInputs?.[_oldKey] || '',
            },
          });
        } else {
          onFilterChange({
            ...filter,
            [oldKey]: '',
            sharedFormInputs: {
              [newKey]: filter[oldKey] || '',
            },
          });
        }
      } else {
        // それ以外のフィールドでの検索
        if (oldKey.startsWith(prefix)) {
          const _oldKey = oldKey.replace(
            prefix,
            ''
          ) as SharedBookingFormElement['id'];
          onFilterChange({
            ...filter,
            [value]: filter.sharedFormInputs?.[_oldKey] || '',
            sharedFormInputs: {},
          });
        } else {
          onFilterChange({
            ...filter,
            [oldKey]: '',
            [value]: filter[oldKey] || '',
            sharedFormInputs: {},
          });
        }
      }
      setFilterKey(value);
    },
    [filter, filterKey, onFilterChange]
  );

  return (
    <VStack
      spacing={{ base: '8px', md: '8px' }}
      justifyContent={'flex-start'}
      alignItems={{ base: 'flex-start', lg: 'flex-start' }}
      divider={<Divider orientation={isPC ? 'vertical' : 'horizontal'} />}
      fontSize={{ base: 'sm', lg: 'md' }}
    >
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w={'full'}
        justifyContent={'flex-start'}
      >
        <Select
          w={'200px'}
          onChange={onSelectChange}
          value={selectDefaultValue}
        >
          <option value={'displayName'}>LINEユーザー名</option>
          <option value={'displayNameByProvider'}>ポチコ上の別名</option>

          {/* フォームの回答に対する検索 */}
          {enabledSharedBookingFormElements.flatMap((element) => {
            if (LINE_USER_FILTER_FORM_ELEMENT_IDS.includes(element.id)) {
              return [
                <option
                  key={element.type}
                  value={`sharedFormInputs.${element.type}`}
                >
                  {element.name}
                </option>,
              ];
            } else {
              return [];
            }
          })}
        </Select>
        <Input
          placeholder={`検索したい文字列を入力${
            filterKey.startsWith(prefix) ? '(前方一致)' : ''
          }`}
          textAlign={'left'}
          type={'text'}
          w={{ base: 'full', md: '500px' }}
          defaultValue={inputDefaultValue}
          onChange={(e) => {
            const value = e.target.value;
            if (filterKey.startsWith(prefix)) {
              const key = filterKey.replace(prefix, '');
              onFilterChange({
                ...filter,
                sharedFormInputs: {
                  [key]: value !== '' ? value : undefined,
                },
              });
            } else {
              onFilterChange({
                ...filter,
                [filterKey]: e.target.value,
              });
            }
          }}
        />
      </Stack>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '8px', md: '8px' }}
        justifyContent={'center'}
        alignItems={{ base: 'flex-start', lg: 'center' }}
        divider={<Divider orientation={isPC ? 'vertical' : 'horizontal'} />}
        fontSize={{ base: 'sm', lg: 'md' }}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
          spacing={'4px'}
        >
          <Text fontSize={'12px'} whiteSpace={'nowrap'}>
            ステータス
          </Text>

          <Select
            // placeholder={'フィルタなし'}
            defaultValue={filter.status}
            onChange={(e) => {
              onFilterChange({
                ...filter,
                status: e.target.value as 'active' | 'blocked',
              });
            }}
          >
            {lineUserStatusChoices.map((choice) => (
              <option key={choice.id} value={choice.id}>
                {choice.name}
              </option>
            ))}
          </Select>
        </Stack>

        <Stack
          direction={{ base: 'row', md: 'column' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
          spacing={'4px'}
        >
          <Text fontSize={'12px'} whiteSpace={'nowrap'}>
            最終予約作成日時
          </Text>

          <Stack direction={{ base: 'column', lg: 'row' }}>
            <Input
              paddingX={'2px'}
              w={'8em'}
              textAlign={'center'}
              type={'date'}
              value={
                filter.lastBookedAt?.start ||
                today.add(-6, 'month').format('YYYY-MM-DD')
              }
              // value={filter.lastBookedAt.start}
              onChange={(e) => {
                const start = e.target.value;
                const end = (() => {
                  if (
                    filter.lastBookedAt?.end &&
                    start > filter.lastBookedAt.end
                  ) {
                    return dayjs(start).add(1, 'day').format('YYYY-MM-DD');
                  } else {
                    return filter.lastBookedAt?.end;
                  }
                })();
                onFilterChange({
                  ...filter,
                  lastBookedAt: { start, end },
                });
              }}
            />

            <HStack>
              <Text>〜</Text>
              <Input
                paddingX={'2px'}
                w={'8em'}
                textAlign={'center'}
                type={'date'}
                value={filter.lastBookedAt?.end || today.format('YYYY-MM-DD')}
                // value={filter.lastBookedAt.end}
                onChange={(e) => {
                  const end = e.target.value;
                  const start = (() => {
                    if (
                      filter.lastBookedAt?.start &&
                      end < filter.lastBookedAt?.start
                    ) {
                      return dayjs(end).add(-1, 'day').format('YYYY-MM-DD');
                    } else {
                      return filter.lastBookedAt?.start;
                    }
                  })();
                  onFilterChange({
                    ...filter,
                    lastBookedAt: { start, end },
                  });
                }}
              />
            </HStack>
          </Stack>
        </Stack>
      </Stack>
    </VStack>
  );
};
