import {
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  DndContext,
  DragEndEvent,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  arraySwap,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { BookingMenuForConsole as BookingMenu } from '../../../../firebase/types';
import { useBulkUpdateBookingMenus } from '../../../../hooks/bookingMenus';
import {
  LocationFrom,
  createLocationFrom,
} from '../../../../hooks/locationState';
import { resourcePath } from '../../../../hooks/useUrlPath';
import { Layout } from '../../../ui/Layout';
import { LineBackground } from '../../../ui/LineBackground';
import { useLoadingOverlayContext } from '../../../ui/LoadingOverlay';
import { BookingMenuPreviewWithoutBackground } from '../BookingMenuPreview';
import { BookingMenuActionButtons } from './BookingMenuActionButtons';

export const PublishedBookingMenusViewSP = ({
  providerAccount,
  bookingMenus,
}: {
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
}) => {
  const locationFrom = React.useMemo(
    () =>
      createLocationFrom(
        resourcePath({
          resourceName: 'bookingMenu',
          action: 'list',
          providerAccount,
        })
      ),
    [providerAccount]
  );

  const menus = React.useMemo(() => {
    return bookingMenus.map((bookingMenu, index) => {
      return (
        <BookingMenuLinePreviewItem
          key={`preview-${bookingMenu.id}-${bookingMenu.displayPriority}-${index}`}
          providerAccount={providerAccount}
          bookingMenu={bookingMenu}
          index={index}
          locationFrom={locationFrom}
        />
      );
    });
  }, [bookingMenus, locationFrom, providerAccount]);
  if (bookingMenus.length === 0) {
    return <Text>公開中の予約メニューがありません</Text>;
  }

  return (
    <VStack w={{ base: 'full', md: 'fit-content' }} alignItems={'flex-start'}>
      <LineBackground>
        <HStack
          gap={'20px'}
          spacing={0}
          px={'12px'}
          py={'32px'}
          w={'full'}
          // flexWrap={'wrap'}
          alignItems={'flex-start'}
          overflowX={'auto'}
        >
          {menus}
        </HStack>
      </LineBackground>
    </VStack>
  );
};

const BookingMenuLinePreviewItem: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  index: number;
  locationFrom: LocationFrom;
}> = ({ providerAccount, bookingMenu, index, locationFrom }) => {
  return (
    <VStack w={'max-content'} gap={'8px'} spacing={0}>
      <HStack
        spacing={0}
        w={'full'}
        fontSize={'xs'}
        gap={0}
        justifyContent={'space-between'}
        bgColor={'gray.50'}
        color={'gray.600'}
        h={'40px'}
        borderRadius={'4px'}
      >
        <BookingMenuActionButtons
          providerAccount={providerAccount}
          bookingMenu={bookingMenu}
          locationFrom={locationFrom}
        />
      </HStack>

      <BookingMenuPreviewWithoutBackground
        bookingMenu={bookingMenu}
        showImage={Boolean(bookingMenu.imageUrl)}
      />
    </VStack>
  );
};

export const OrderingPublishedBookingMenusViewSP: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
  onCancel: () => void;
}> = ({ providerAccount, bookingMenus, onCancel }) => {
  const updateDisplayPriorityMutation =
    useBulkUpdateBookingMenus(providerAccount);

  const overlayContext = useLoadingOverlayContext();
  const [orderedBookingMenus, setOrderedBookingMenus] = React.useState(() =>
    bookingMenus.sort((a, b) => b.displayPriority - a.displayPriority)
  );
  React.useEffect(() => {
    // 公開と下書きの切り替えがstateまで反映されないのでここで反映させる
    if (bookingMenus.length !== orderedBookingMenus.length) {
      setOrderedBookingMenus(
        bookingMenus.sort((a, b) => b.displayPriority - a.displayPriority)
      );
    }
  }, [bookingMenus, orderedBookingMenus.length]);

  const toast = useToast();
  const onChangeDisplayPriority = React.useCallback(
    (orderedBookingMenus: BookingMenu[]) => {
      const newOrderedBookingMenus = orderedBookingMenus.map((menu, index) => ({
        ...menu,
        displayPriority: orderedBookingMenus.length - index + 1, // 下書き→公開にしたときにdisplayPriorityを1にしているので2始まりにしておく
      }));
      overlayContext.onOpen();
      return updateDisplayPriorityMutation.mutateAsync(
        { bookingMenus: newOrderedBookingMenus },
        {
          onSuccess: () => {
            setOrderedBookingMenus(newOrderedBookingMenus);
          },
          onError: (e) => {
            toast({
              title: `エラーが発生しました ${e}`,
              status: 'error',
            });
          },
          onSettled: () => overlayContext.onClose(),
        }
      );
    },
    [overlayContext, toast, updateDisplayPriorityMutation]
  );
  const handleDragEnd = React.useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!over) {
        return;
      }
      if (active.id !== over.id) {
        const oldIndex = orderedBookingMenus.findIndex(
          (menu) => menu.id === active.id
        );
        const newIndex = orderedBookingMenus.findIndex(
          (menu) => menu.id === over.id
        );
        const newOrderedBookingMenus = arrayMove(
          orderedBookingMenus,
          oldIndex,
          newIndex
        );
        setOrderedBookingMenus(newOrderedBookingMenus);
        onChangeDisplayPriority(newOrderedBookingMenus);
      }
    },
    [onChangeDisplayPriority, orderedBookingMenus]
  );

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 200,
      tolerance: 5,
    },
  });
  const sensors = useSensors(touchSensor);
  const menus = React.useMemo(() => {
    return orderedBookingMenus.map((bookingMenu, index) => {
      return (
        <BookingMenuToOrderInList
          key={bookingMenu.id}
          orderedBookingMenus={orderedBookingMenus}
          bookingMenu={bookingMenu}
          onChangeDisplayPriority={onChangeDisplayPriority}
          index={index}
        />
      );
    });
  }, [onChangeDisplayPriority, orderedBookingMenus]);
  return (
    <Layout pageTitle="公開中のメニューの並び替え" hasBackButton={onCancel}>
      <DndContext
        onDragEnd={handleDragEnd}
        sensors={sensors}
        // collisionDetection={closestCenter}
      >
        <SortableContext
          items={orderedBookingMenus}
          strategy={verticalListSortingStrategy}
        >
          <VStack
            w={'full'}
            alignItems={'flex-start'}
            spacing={0}
            p={0}
            m={0}
            divider={<Divider borderColor={'gray.200'} />}
            borderTopWidth={'1px'}
            overflowY={'scroll'}
            borderBottomWidth={'1px'}
            borderColor={'gray.200'}
          >
            {menus}
          </VStack>
        </SortableContext>
      </DndContext>
    </Layout>
  );
};

const HamburgerIcon = <GiHamburgerMenu size={'16px'} />;

const BookingMenuToOrderInList: React.FC<{
  orderedBookingMenus: BookingMenu[];
  bookingMenu: BookingMenu;
  index: number;
  onChangeDisplayPriority: (orderedBookingMenus: BookingMenu[]) => void;
}> = ({ orderedBookingMenus, bookingMenu, index, onChangeDisplayPriority }) => {
  const sortable = useSortable({
    id: bookingMenu.id,
  });
  const style: React.CSSProperties = {
    transform: sortable.transform
      ? CSS.Transform.toString({
          ...sortable.transform,
          scaleX: 1,
          scaleY: 1,
        })
      : undefined,
    transition: sortable.transition,
    touchAction: 'none',
  };
  return (
    <HStack
      key={`preview-${bookingMenu.id}-${bookingMenu.displayPriority}-${index}`}
      w={'full'}
      alignItems={'center'}
      justifyContent={'space-between'}
      py={'12px'}
    >
      <VStack w={'38px'}>
        <Button
          width={'24px'}
          height={'24px'}
          borderWidth={'1px'}
          borderColor={'gray.200'}
          bgColor={'white'}
          color={'gray.500'}
          borderRadius={'2px'}
          isDisabled={index === 0}
          p={0}
          onClick={() => {
            const reordered = arraySwap(orderedBookingMenus, index, index - 1);
            return onChangeDisplayPriority(reordered);
          }}
        >
          <Icon as={MdKeyboardArrowUp} color={'gray.500'} boxSize={'24px'} />
        </Button>
        <Text whiteSpace={'nowrap'}>{index + 1}番目</Text>
        <Button
          width={'24px'}
          height={'24px'}
          borderWidth={'1px'}
          p={0}
          borderColor={'gray.200'}
          bgColor={'white'}
          color={'gray.500'}
          borderRadius={'2px'}
          isDisabled={index === orderedBookingMenus.length - 1}
          onClick={() => {
            const reordered = arraySwap(orderedBookingMenus, index, index + 1);
            return onChangeDisplayPriority(reordered);
          }}
        >
          <Icon as={MdKeyboardArrowDown} color={'gray.500'} boxSize={'24px'} />
        </Button>
      </VStack>
      <HStack
        w={'full'}
        ref={sortable.setNodeRef}
        style={{
          ...style,
          touchAction: 'manipulation',
        }}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <BookingMenuToOrder bookingMenu={bookingMenu} />
        <IconButton
          aria-label="menu"
          icon={HamburgerIcon}
          variant={'transparent-clickable'}
          cursor={'grab'}
          {...sortable.attributes}
          {...sortable.listeners}
        />
      </HStack>
    </HStack>
  );
};

const BookingMenuToOrder: React.FC<{
  bookingMenu: BookingMenu;
}> = ({ bookingMenu }) => {
  return (
    <HStack w={'full'}>
      {bookingMenu.imageUrl && (
        <Image
          src={bookingMenu.imageUrl}
          alt={bookingMenu.name}
          width={'96px'}
          height={'64px'}
        />
      )}
      <VStack
        w={'full'}
        alignItems={'left'}
        // overflowX={'clip'}
      >
        <Text fontWeight={'bold'} fontSize={20}>
          {bookingMenu.name}
        </Text>
        <Text fontSize={13} textOverflow={'ellipsis'}>
          {bookingMenu.description}
        </Text>
      </VStack>
    </HStack>
  );
};
