import {
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Booking,
  BookingMenu,
  LineUser,
  ProviderAccount,
  Spot,
  dateStringWithWeekDay,
  notUndefinedOrNull,
} from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useFetchBookings } from '../../../hooks/booking';
import { useFetchLineUsers } from '../../../hooks/lineUser';
import { LocationFrom, createLocationFrom } from '../../../hooks/locationState';
import { useUrlParamState } from '../../../hooks/useUrlParamState';
import { resourcePath } from '../../../hooks/useUrlPath';
import { BookedUserBadge } from '../../ui/BookingUser';
import { CalendarCell, CalendarView } from '../../ui/CalendarView';
import { NavigationButton } from '../../ui/NavigationButton';
import { SpotFilter } from '../Spots';

type BookingCalendarViewFilter = {
  'calendar.bookingMenuId'?: string;
  yearMonth: {
    year: number;
    month: number;
  };
};

type BookingWithLineUser = Booking & { lineUser: LineUser | undefined };
export const BookingListCalendarView: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
}> = ({ providerAccount, bookingMenuList }) => {
  const now = React.useMemo(() => dayjs(), []);
  const { urlState: _filter, setUrlState: setFilter } =
    useUrlParamState<BookingCalendarViewFilter>(
      ['calendar.bookingMenuId', 'yearMonth'],
      {
        defaultValue: {
          yearMonth: {
            year: now.year(),
            month: now.month() + 1,
          },
        },
      }
    );
  const filter = React.useMemo<SpotFilter | undefined>(() => {
    const startOfMonth = dayjs()
      .year(_filter.yearMonth.year)
      .month(_filter.yearMonth.month - 1)
      .startOf('month');

    if (!_filter['calendar.bookingMenuId']) {
      return undefined;
    }
    return {
      bookingMenuId: _filter['calendar.bookingMenuId'],
      displayDate: {
        start: startOfMonth.format('YYYY-MM-DD'),
        end: startOfMonth.endOf('month').format('YYYY-MM-DD'),
      },
    };
  }, [_filter]);

  const { toNextMonth, toPrevMonth } = React.useMemo(() => {
    const nextMonth = dayjs()
      .year(_filter.yearMonth.year)
      .month(_filter.yearMonth.month - 1)
      .add(1, 'month');
    const prevMonth = dayjs()
      .year(_filter.yearMonth.year)
      .month(_filter.yearMonth.month - 1)
      .subtract(1, 'month');
    return {
      toNextMonth: () =>
        setFilter({
          ...filter,
          yearMonth: {
            year: nextMonth.year(),
            month: nextMonth.month() + 1,
          },
        }),
      toPrevMonth: () =>
        setFilter({
          ...filter,
          yearMonth: {
            year: prevMonth.year(),
            month: prevMonth.month() + 1,
          },
        }),
    };
  }, [_filter.yearMonth.month, _filter.yearMonth.year, filter, setFilter]);

  const bookingsQuery = useFetchBookings({
    providerAccount,
    filter: filter!,
    perPage: 1000,
    sort: {
      direction: 'asc',
      field: 'dateTimeForSort',
    },
    page: 1,
    enabled: Boolean(filter),
  });
  const lineUserQuery = useFetchLineUsers({
    providerAccount,
    filter: {
      ids:
        bookingsQuery.data
          ?.map((b) => b.lineUserId)
          .filter(notUndefinedOrNull) || [],
      archived: undefined,
    },
    perPage: bookingsQuery.data?.length || 0,
    sort: { field: 'id', direction: 'asc' },
    page: 1,
    enabled: bookingsQuery.data && bookingsQuery.data.length > 0,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clickedCell, setClickedCell] = React.useState<{
    date: dayjs.Dayjs;
    bookingsWithLineUsers: BookingWithLineUser[];
  } | null>(null);
  const onClickCell = React.useCallback(
    (date: dayjs.Dayjs, bookingsWithLineUsers: BookingWithLineUser[]) => {
      setClickedCell({ date, bookingsWithLineUsers });
      onOpen();
    },
    [onOpen]
  );
  const locationFrom = React.useMemo(() => {
    return createLocationFrom(
      resourcePath({
        providerAccount,
        resourceName: 'booking',
        action: 'list',
        urlParams: {
          tab: 'calendar',
          bookingMenuId: _filter['calendar.bookingMenuId'] || '',
          yearMonth: JSON.stringify(_filter.yearMonth),
        },
      })
    );
  }, [_filter, providerAccount]);

  React.useEffect(() => {
    if (!_filter['calendar.bookingMenuId'] && bookingMenuList.length > 0) {
      setFilter({
        ..._filter,
        'calendar.bookingMenuId': bookingMenuList[0].id,
      });
    }
  }, [_filter, bookingMenuList, setFilter]);

  return (
    <VStack
      alignItems={'center'}
      width={'full'}
      padding={0}
      gap={0}
      // paddingTop={'16px'}
    >
      <VStack w={'max-content'} alignItems={'center'} gap={'24px'}>
        <VStack w={'full'} alignItems={'flex-start'}>
          <Text>予約メニュー</Text>
          <Select
            value={_filter['calendar.bookingMenuId']}
            maxW={{ base: '90vw', md: '50vw' }}
            placeholder="選択してください"
            onChange={(e) => {
              setFilter({
                ..._filter,
                'calendar.bookingMenuId': e.target.value,
              });
            }}
          >
            {/* <option value={''}>選択してください</option> */}
            {bookingMenuList.map((menu) => (
              <option key={menu.id} value={menu.id}>
                {menu.name}
              </option>
            ))}
          </Select>
        </VStack>

        {bookingsQuery.isFetching || lineUserQuery.isFetching ? (
          <Spinner />
        ) : bookingsQuery.error || lineUserQuery.error ? (
          <VStack alignItems={'flex-start'}>
            <Text>エラーが発生しました</Text>
            <Text>{String(bookingsQuery.error || lineUserQuery.error)}</Text>
          </VStack>
        ) : bookingsQuery.isSuccess && lineUserQuery.isSuccess ? (
          <VStack>
            <HStack
              w={'full'}
              justifyContent={'center'}
              alignItems={'center'}
              // p={'12px'}
              // borderRadius={'4px'}
              whiteSpace={'nowrap'}
            >
              <IconButton
                onClick={toPrevMonth}
                variant={'transparent-clickable'}
                icon={<MdKeyboardArrowLeft />}
                aria-label={'先月'}
              />
              <Text fontWeight={'bold'} width={'6em'} textAlign={'center'}>
                {_filter.yearMonth.year}年{_filter.yearMonth.month}月
              </Text>
              <IconButton
                onClick={toNextMonth}
                variant={'transparent-clickable'}
                icon={<MdKeyboardArrowRight />}
                aria-label={'翌月'}
              />
            </HStack>
            <CalendarView
              yearMonth={{
                year: _filter.yearMonth.year,
                month: _filter.yearMonth.month,
              }}
              dateComponent={(date) => {
                const dateString = date.format('YYYY-MM-DD');
                const bookingsWithLineUsers = (
                  bookingsQuery.data?.filter(
                    (booking) => booking.date === dateString
                  ) || []
                ).map((booking) => {
                  if (booking.lineUserId) {
                    const lineUser = lineUserQuery.data?.find(
                      (lu) => lu.id === booking.lineUserId
                    );
                    return { ...booking, lineUser };
                  } else {
                    return { ...booking, lineUser: undefined };
                  }
                });
                return (
                  <CalendarCell
                    key={`spot-list-cell-${dateString}`}
                    date={date}
                    onClick={() => onClickCell(date, bookingsWithLineUsers)}
                    count={bookingsWithLineUsers.length}
                  />
                );
              }}
            />
          </VStack>
        ) : (
          <></>
        )}
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent paddingY={6} justifyContent={'center'}>
          <ModalHeader justifyContent={'center'}>
            {clickedCell && dateStringWithWeekDay(clickedCell.date)}
          </ModalHeader>
          <ModalCloseButton />
          <BookingListModalBody
            clickedCell={clickedCell}
            providerAccount={providerAccount}
            locationFrom={locationFrom}
            _filter={_filter}
          />
        </ModalContent>
      </Modal>
    </VStack>
  );
};

const BookingListModalBody: React.FC<{
  clickedCell: {
    date: dayjs.Dayjs;
    bookingsWithLineUsers: BookingWithLineUser[];
  } | null;
  providerAccount: ProviderAccount;
  locationFrom: LocationFrom;
  _filter: BookingCalendarViewFilter;
}> = ({ clickedCell, providerAccount, locationFrom, _filter }) => {
  const bookingsBySpot = React.useMemo(() => {
    return (clickedCell?.bookingsWithLineUsers || [])
      .reduce((acc, booking) => {
        const spotId = booking.spotId;
        const idx = acc.findIndex((b) => b.spotId === spotId);
        if (idx > 0) {
          acc[idx].bookings.push(booking);
        } else {
          acc.push({
            spotId,
            startTime: booking.startTime,
            bookings: [booking],
          });
        }
        return acc;
      }, [] as { spotId: Spot['id']; startTime: string; bookings: BookingWithLineUser[] }[])
      .sort((a, b) => a.startTime.localeCompare(b.startTime));
  }, [clickedCell?.bookingsWithLineUsers]);

  return (
    <ModalBody>
      {clickedCell && (
        <VStack
          w={'full'}
          alignItems={'flex-start'}
          borderRadius={'lg'}
          spacing={'8px'}
        >
          <VStack
            w={'full'}
            alignItems={'flex-start'}
            borderRadius={'lg'}
            spacing={'8px'}
          >
            {(clickedCell.bookingsWithLineUsers || []).length === 0 ? (
              <Text>設定なし</Text>
            ) : (
              bookingsBySpot.map(({ startTime, bookings }) => {
                // const _bookings = bookings.map((b) => {
                //   const name = b.userName
                //     ? b.userName
                //     : b.lineUser?.displayNameByProvider ||
                //       b.lineUser?.displayName ||
                //       b.lineUserId;
                //   return { id: b.id, name };
                // });
                return (
                  <HStack
                    w={'full'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    spacing={'8px'}
                    borderWidth={'1px'}
                    px={'12px'}
                    py={'8px'}
                    borderRadius={'4px'}
                    borderColor={'gray.300'}
                    overflowX={'auto'}
                  >
                    <Text>{startTime}~</Text>
                    <HStack
                      w={'full'}
                      alignItems={'center'}
                      justifyContent={'flex-start'}
                    >
                      {bookings.map((booking) => (
                        <BookedUserBadge
                          key={`booking-${booking.id}`}
                          booking={booking}
                          providerAccount={providerAccount}
                          locationFrom={locationFrom}
                          lineUser={booking.lineUser}
                        />
                      ))}
                    </HStack>
                  </HStack>
                );
              })
            )}
          </VStack>
          <HStack w={'full'} justifyContent={'flex-end'}>
            <NavigationButton
              locationFrom={locationFrom}
              to={{
                providerAccount,
                resourceName: 'booking',
                action: 'create',
                urlParams: {
                  date: clickedCell.date.format('YYYY-MM-DD'),
                  bookingMenuId: _filter['calendar.bookingMenuId'] || '',
                },
              }}
              variant={'white-blue'}
            >
              予約を作成
            </NavigationButton>
          </HStack>
        </VStack>
      )}
    </ModalBody>
  );
};
